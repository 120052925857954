.App {
  display: flex;
  min-height: 100vh;
  background-color: #0f172a;
}

/* Auth Container Styles */
.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(to bottom right, rgba(59, 130, 246, 0.1), rgba(147, 51, 234, 0.1));
  padding: 20px;
}

.auth-form {
  background-color: rgba(30, 41, 59, 0.7);
  backdrop-filter: blur(10px);
  padding: 2rem;
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.auth-form h2 {
  color: #f8fafc;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  text-align: center;
}

.auth-form input {
  width: 100%;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  color: #f8fafc;
  transition: all 0.3s ease;
}

.auth-form input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
}

.auth-form input::placeholder {
  color: #94a3b8;
}

.auth-form button {
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.auth-form button:hover {
  background-color: #2563eb;
}

.auth-form button:disabled {
  background-color: #94a3b8;
  cursor: not-allowed;
}

.auth-toggle {
  margin-top: 1rem;
  background: none;
  border: none;
  color: #3b82f6;
  cursor: pointer;
  font-size: 0.875rem;
  transition: color 0.3s ease;
}

.auth-toggle:hover {
  color: #2563eb;
}

.error-message {
  background-color: rgba(239, 68, 68, 0.1);
  border: 1px solid rgba(239, 68, 68, 0.2);
  color: #ef4444;
  padding: 0.75rem;
  margin: 1rem 0;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  text-align: center;
}

/* Chat Interface Styles */
.chat-window {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #1e293b;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

.message {
  max-width: 80%;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
}

.user-message {
  margin-left: auto;
  background-color: #3b82f6;
  color: white;
}

.assistant-message {
  margin-right: auto;
  background-color: #334155;
  color: #f8fafc;
}

.chat-input {
  padding: 1rem;
  background-color: #0f172a;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.chat-form {
  display: flex;
  gap: 0.5rem;
}

.chat-input textarea {
  flex: 1;
  padding: 0.75rem;
  background-color: #1e293b;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  color: #f8fafc;
  resize: none;
  min-height: 2.5rem;
}

.chat-input button {
  padding: 0.75rem 1.5rem;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chat-input button:hover {
  background-color: #2563eb;
}

.chat-input button:disabled {
  background-color: #94a3b8;
  cursor: not-allowed;
}

/* Loading States */
.loading {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #3b82f6;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .auth-form {
    padding: 1.5rem;
  }

  .message {
    max-width: 90%;
  }

  .chat-input {
    padding: 0.75rem;
  }
}